import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from "gatsby"

import Seo from "../components/Seo"
import Hero from '../components/Hero'
import CityMapSection from '../components/CityMapSection'
import WardContext from '../components/WardContext'
import CandidateProfile from '../components/CandidateProfile'
import SignUpSection from '../components/SignUpSection'


const CandidatesSection = ({ wardCandidates, currentWard }) => {

  const councillors = wardCandidates?.filter( candidate => candidate.candidate_kind_of_member === 'Common Councillor' );
  const aldermans = wardCandidates?.filter( candidate => candidate.candidate_kind_of_member === 'Alderman' );

  return (
    <>
      <div className="container container--section-headline">
        <div className="row">
          <div className="col-md-12 headline-wrapper">

            <div className="background-margin"></div>
            <h3 className="candidates__headline">Candidates for <b>{currentWard.replace("_"," ")}</b></h3>

          </div>
        </div>
      </div>
      <div className="candidates">
        <div className="container candidates__section">

          <div className="row candidates__row">

            <h4>Candidates to ward alderman</h4>

              { 
                councillors?.map( councillor => 
                  <div className="col-md-4">
                    <CandidateProfile
                      key={councillor?.candidate_name}
                      name={councillor?.candidate_name}
                      party={councillor?.candidate_party?.toLowerCase()}
                      picture={councillor?.candidate_picture}
                    />
                  </div>
                )
              }

          </div>

          <div className="row candidates__row">

            <h4>Candidates to Councillor</h4>

              { 
                aldermans?.map( councillor => 
                  <div className="col-md-4">
                    <CandidateProfile
                      key={councillor?.candidate_name}
                      name={councillor?.candidate_name} 
                      party={councillor?.candidate_party?.toLowerCase()}
                      picture={councillor?.candidate_picture}
                    />
                  </div>
                )
              }

          </div>

          <div className="row">
            <div className="col-md-12">
              <Link to="/places" className="button">Find your voting place ›</Link>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default function Candidates({ data }) {
  const [ currentWard ] = useContext(WardContext);
  const [ wardCandidates, setWardCandidates ] = useState([]);

  // = = = = = = = = 
  // Forestry Data
  const candidates = data.allMarkdownRemark.nodes;

  // = = = = = = = = 
  // Hooks

  // Filter candidates when currentWard changes
  const getCandidates = () => {
    const filteredCandidates = candidates?.filter( candidate => candidate?.frontmatter?.ward_name === currentWard.replace("_"," ") );
    setWardCandidates(filteredCandidates[0]?.frontmatter?.ward_candidates);
  }

  useEffect( () => getCandidates(), [currentWard]);

  return(
    <>
      <Seo title="Candidates" />

      <Hero 
        headline="Meet your candidates"
        size = {'medium'}
      >
        <StaticImage 
          className="hero__picture"
          src="../../static/media/updates-hero.jpg" 
          alt=""
          loading="eager"
        />
      </Hero>

      <CityMapSection headline="Select your ward in the map to see your candidates:" />
        
      {
        // currentWard
        //   ? <CandidatesSection wardCandidates={ wardCandidates } currentWard={ currentWard } />
        //   : ''
      }

      <SignUpSection />
    </>
  )
}

// = = = = = = = = 
// GraphQL
export const query = graphql`
  query CandidatesQuery {
    allMarkdownRemark(filter: {frontmatter: {type: {eq: "ward"}}}) {
      nodes {
        frontmatter {
          ward_name
          ward_candidates {
            candidate_name
            candidate_party
          }
        }
      }
    }
  }
`;
